

<template>

  <div class="col-md-7 col-sm-7 mt-10 boxmain" >
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('advert.title')}}
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12 mrb-25">
                <img class="lazy" src="@/assets/default/images/qc1.png">
              </div>
            </div>
          </div>

          <div class="container" >
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="contact-form" v-if="is_loading">
                        <form method="post" id="my_submit" enctype="multipart/form-data" @submit="onSubmit">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="name" :placeholder="$t('form.name')" class="form-control" required="">
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="phone" :placeholder="$t('form.phone')" class="form-control" required="">
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <input type="email" name="email" placeholder="Email" class="form-control" required="">
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <textarea rows="3" name="content" :placeholder="$t('form.content')" class="form-control" required=""></textarea>
                              </div>
                            </div>
                             <div class="col-lg-12">
                              <div class="form-group">
                                <div class="vcaptcha">
                                  <input class="form-control" :placeholder="$t('form.capcha')" name="proctectcode" v-model="my_capcha" type="text" value="" data-bv-field="captcha" required="required" autocomplete="off">

                                  
                                </div>
                              </div>
                               <span class="vimage" v-if="capcha!=null">
                                <img class="lazy" :src="'data:image/jpeg;base64, '+capcha.captchBase64Data" style="float: left;">
                                

                                <div style="border:1px solid #ddd;float: left;padding: 4px; margin-left: 5px;" @click="reloadCapcha()">
                                <img src="@/assets/refresh.png" />
                                 </div>

                                 <input type="hidden"  name="clientId" :value="capcha.clientId" />
                              </span>
                            </div>
                            <div class="col-lg-12 text-center">
                              <div class="form-group">
                                <button type="submit" name="submit" class="btn_news cs-btn-one btn-md btn-round btn-primary-color element-shadow" value="Send">{{$t('form.submit')}}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div class="contact-form" v-else>
                         <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                  <Skeleton height="30px"/>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                               <Skeleton height="30px"/>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                               <Skeleton height="30px"/>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                               <Skeleton height="310px"/>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group">
                                <div class="vcaptcha">
                                 <Skeleton height="30px"/>
                                </div>
                              </div>
                              
                            </div>
                            <div class="col-lg-12 text-center">
                              <div class="form-group">
                               <Skeleton height="30px"/>
                              </div>
                            </div>
                          </div>
                          </SkeletonTheme>
                      </div>


                    </div>
                  </div>

                  <div class="row mrt-30">
                    <div class="col-lg-6 col-xl-6">
                      <div class="contact-block d-flex mrb-30">
                        <div class="contact-icon">
                          <i class="webex-icon-map1"></i>
                        </div>
                        <div class="contact-details mrl-20">
                          <h5 class="icon-box-title mrb-10">{{$t('advert.name')}}</h5>
                          <p class="mrb-0">292 Đinh Bộ Lĩnh, phường 26, Quận Bình Thạnh, Hồ Chí Minh</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-xl-6">
                      <div class="contact-block d-flex mrb-30">
                        <div class="contact-icon">
                          <i class="webex-icon-Phone2"></i>
                        </div>
                        <div class="contact-details mrl-20">
                          <h5 class="icon-box-title mrb-10">{{$t('advert.phone')}}</h5>
                          <p class="mrb-0">(028) 899 1607 - 1900571292</p><br>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
  </template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';
export default {
  
 
  data() {
    return {
     
      myurl:window.my_url,
      
      my_data:null,
      keyword:"",
      is_loading:true,
      capcha:null,
      my_capcha:""

    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
    
  },
  created(){
        

          
      this.reloadCapcha();
          
          
  },
  methods:{
    reloadCapcha(){
         axios.post(window.my_api+"api/captchar/generate-captchars")
                  .then( (res)=>{
                  
                    if(res.status==200){

                         this.capcha=res.data.data;
                         //console.log(this.capcha);

                                                                   
                    }else {
                      console.log("ERROR",res);
                    }
                     

                });
    },
    onSubmit(event){

           event.preventDefault();
           if(this.my_capcha.toUpperCase()!=this.capcha.captchaCode.toUpperCase()){
              alert("Vui lòng nhập đúng capcha");
              this.reloadCapcha();
              return false;
           }

           this.is_loading=false;

           var form_data = new FormData(document.getElementById('my_submit'));
           axios.post(window.my_api+"api/advertising/create-or-update-advertising",form_data)
                .then((res)=>{
                  this.is_loading=true;
                    if(res.status==200){

                      if(res.data.errors.length>0){
                          alert("Vui lòng điền đủ thông tin.");
                      }else {
                          
                          alert(" Cảm ơn bạn đã liên hệ đến chúng tôi. Chúng tôi sẽ liên hệ đến bạn một cách sớm nhất. ");
                          document.getElementById('my_submit').reset();

                      }

                    }
                    
                });
         
           return false;
      }
  }

}
</script> 